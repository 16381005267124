/**
 * ROUTES
 *
 * ===============================================
 *
 * This object depicts the component url structure.
 * It contains a key-value pair of components and their respective URLs
 *
 */

export const Routes = {
  home: "/home",
  questionnaire: "/questionnaire",
  questionnaire_steps: "/questionnaire-steps",
  questionnaire_completed: "/questionnaire-completed",
  faqs: "/faqs",
  about: "/about",
  contact: "/contact",
};
