import React from "react";
import { Heading } from "../heading";
import { LinkButton, Section } from "../../../components";
import { Routes } from "../../../router";

const Completed = () => {
  return (
    <Section sectionClassName="lg:w-[872px] text-blackBg">
      <Heading />
      <div className="mt-4 rounded-2xl overflow-hidden mb-20 bg-evergreen60 py-5 lg:py-10 px-4 lg:px-20 flex flex-col justify-center items-center">
        <p className="text-center uppercase font-bold text-base lg:text-xl text-blackBg max-w-[616px] ">
          Thank you for submitting your form. Your information has been
          successfully received. We will be in touch with you shortly.
        </p>{" "}
        <LinkButton
          to={Routes.home}
          variant="primary"
          className="rounded-full text-whitishGray w-52 text-center text-xs mt-3 "
        >
          Return to Homepage
        </LinkButton>
      </div>
    </Section>
  );
};

export { Completed };
