import {
  CheckIcon,
  FamOfThree,
  FatherDaughter,
  WhyAdequate,
  WhyEfficient,
  WhySeamless,
} from "../../../assets";
import { Section } from "../../../components";

const plans = [
  "Strategic Savings Plans",
  "Income Planning",
  "Diverse Investment Options",
  "Tailored Strategies",
  "Flexible Investment Strategies",
  "Holistic Support",
  "Continuous Monitoring & Adjustments",
  "Comprehensive Planning",
];
const WhyMerigame = () => {
  return (
    <Section bgClassName="mt-64 lg:mt-40" sectionClassName="">
      <div className="flex justify-center">
        <p className="glowing-underline mt-4">Why Choose Evergreen</p>
      </div>

      <h2 className="max-w-[876px] text-center mx-auto my-4" data-aos="fade-up">
        Why Evergreen by Meristem is Your Best Choice
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 text-center lg:items-end lg:px-14 gap-10 lg:gap-16">
        <div
          className="flex flex-col gap-2 items-center mt-6 justify-between "
          data-aos="flip-right"
        >
          <img
            src={WhyEfficient}
            alt=""
            className="rounded-full w-[60px] lg:w-[100px]"
          />
          <p className="uppercase font-medium text-base lg:text-xl mt-3">
            Efficient
          </p>
          <p className="max-w-[340px]">
            Efficient estate planning and retirement strategies streamline the
            process, saving you valuable time and effort.
          </p>
        </div>

        <div
          className="flex flex-col gap-2 items-center justify-between "
          data-aos="flip-right"
        >
          <img
            src={WhyAdequate}
            alt=""
            className="rounded-full w-[60px] lg:w-[100px] "
          />
          <p className="uppercase font-medium text-base lg:text-xl mt-3">
            Adequate
          </p>
          <p>
            Adequate estate planning and retirement solutions ensure that all
            aspects of your financial and personal affairs are addressed.
          </p>
        </div>
        <div
          className="flex flex-col gap-2 items-center justify-between "
          data-aos="flip-right"
        >
          <img
            src={WhySeamless}
            alt=""
            className="rounded-full w-[60px] lg:w-[100px]"
          />
          <p className="uppercase font-medium text-base lg:text-xl mt-3">
            Seamless Transfer
          </p>
          <p>
            Seamless transfer mechanisms ensure a smooth transition of assets
            and responsibilities according to your wishes.
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-col gap-6 mt-10">
        <div
          className="bg-blackBg w-[95%] max-w-[1320px] lg:w-[98%] grid grid-cols-1 lg:grid-cols-[auto_580px] rounded-3xl text-paragraphWhite overflow-hidden mx-auto"
          data-aos="fade-right"
        >
          <div className="p-4 lg:p-10 order-2 lg:order-1">
            <p className="uppercase font-medium text-base lg:text-xl text-whitishGray mb-4">
              customized retirement plans
            </p>
            <p className="max-w-[504px]">
              At Evergreen, we understand that every individual’s retirement
              goals and financial situation are unique. Our customized
              retirement plans are tailored to meet your specific needs,
              ensuring that you can enjoy a comfortable and fulfilling
              retirement. Here’s what our personalized approach includes:
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 justify-between mt-4">
              {plans.map((plan) => (
                <p key={plan} className="flex items-center gap-1 mb-2">
                  <img src={CheckIcon} alt="" />{" "}
                  <span className="whitespace-nowrap">{plan}</span>
                </p>
              ))}
            </div>
          </div>
          <div className="order-1 lg:order-2">
            <img src={FatherDaughter} alt="" className="h-full w-full" />
          </div>
        </div>

        <div
          className="bg-blackBg w-[95%] max-w-[1320px] lg:w-[98%] grid grid-cols-1 lg:grid-cols-2 rounded-3xl text-paragraphWhite overflow-hidden mx-auto"
          data-aos="fade-left"
        >
          <div className="">
            <img src={FamOfThree} alt="" className="h-full w-full" />
          </div>
          <div className="p-4 lg:p-10 flex flex-col justify-center">
            <p className="uppercase font-medium text-base lg:text-xl text-whitishGray mb-4">
              Estate Planning
            </p>
            <p className="max-w-[480px]">
              Preserve your wealth and safeguard your legacy with our
              specialized estate planning services. Our experienced team will
              work closely with you to create a comprehensive plan tailored to
              your unique needs and objectives.
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export { WhyMerigame };
