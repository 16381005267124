import Select from "react-select";
import { ArrowDownIcon, TooltipIcon } from "../../assets";
import { useState } from "react";
export type OptionType = { value: string; label: string };

interface SelectInputProps {
  options: OptionType[];
  selectedOption: OptionType | null;
  handleSelectChange: (newValue: OptionType | null) => void;
  placeholder?: string | React.ReactNode;
  label?: string;
  name: string;
  error?: string;
  className?: string;
  parentClassName?: string;
  required?: boolean;
  tooltipContent?: string;
}
const SelectInput: React.FC<SelectInputProps> = ({
  options,
  selectedOption,
  handleSelectChange,
  placeholder,
  label,
  name,
  error,
  className,
  parentClassName,
  required,
  tooltipContent,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className={`${parentClassName} pt-2`}>
      {label && (
        <label className="text-blackText font-medium pl-1 pb-1 flex flex-col">
          <span className="flex items-center">
            {label}

            {/* Conditionally render the tooltip icon */}
            {tooltipContent && (
              <div
                className="relative ml-2"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                {/* Tooltip Icon */}
                <img
                  src={TooltipIcon}
                  alt="Info"
                  className="w-5 h-5 cursor-pointer"
                />

                {/* Tooltip Content */}
                {showTooltip && (
                  <div className="mb-4 absolute bottom-full -right-4 w-[250px] p-3 text-xs bg-evergreen70 text-paragraphWhite rounded-lg">
                    <div className="relative ">
                      <div className="absolute w-0 h-0 border-x-8 border-t-8 border-t-evergreen70 border-x-transparent -bottom-[19px] right-2" />
                      <p>{tooltipContent}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </span>
        </label>
      )}{" "}
      <Select
        defaultValue={selectedOption}
        onChange={handleSelectChange}
        options={options}
        value={selectedOption}
        placeholder={placeholder}
        name={name}
        className={`${className} bg-evergreen60 py-1 px-3 ${
          required ? "border-red" : "border-borders"
        }  border  rounded-lg accent-evergreen70`}
        styles={{
          control: (baseStyles, _) => ({
            ...baseStyles,
            border: 0,
            outline: "none",
            flexGrow: 1,
            height: "100%",
            borderRadius: 6,
            boxShadow: "none",
            backgroundColor: "transparent",
            fontSize: "14px",
            color: "var(--neutral-40)",
          }),

          option: (styles, { isSelected, isFocused }) => {
            return {
              ...styles,
              backgroundColor: isSelected
                ? "#E6D6ED"
                : isFocused
                ? "#E6D6ED"
                : undefined,
            };
          },
        }}
        isSearchable
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => (
            <img src={ArrowDownIcon} alt="location" className="" width={12} />
          ),
        }}
      />
      <small className={`${required ? "text-red" : "text-evergreen60"}`}>
        {" "}
        Required
      </small>
      {error && <p className={"error"}>{error}</p>}
    </div>
  );
};

export { SelectInput };
