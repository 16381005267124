import { useState } from "react";
import { Input, LinkButton, Section, TextArea } from "../../../components";
import {

  Map,
  NewMail,
  NewPhone,
} from "../../../assets";

const GetInTouch = () => {
  const [mailContent, setMailContent] = useState({
    fullname: "",
    phone: "",
    email: "",
    body: "",
  });
  const { fullname, phone, email, body } = mailContent;
  const subject = fullname;

  const generateMailtoLink = () => {
    return `mailto:${"retirementplanning@meristemng.com"}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  return (
    <Section
      bgClassName="relative mt-16"
      sectionClassName="py-8 lg:py-14 text-[#090909CC] flex justify-center items-center "
    >
      <img
        src={Map}
        alt=""
        className="absolute top-0 left-0 right-0 w-full lg:h-3/4"
      />
      <div className="w-full lg:w-[98%] max-w-[1320px] bg-pinkBg z-10 mt-24 lg:mt-56 rounded-3xl py-10 px-0 lg:px-10 lg:p-[60px] lg:flex justify-between gap-[70px]">
        <div className="px-4 lg:px-0 mb-10 lg:mb-0 ">
          <p className="glowing-underline">Contact Us</p>
          <h2 className="max-w-[480px] my-4" data-aos="fade-up">
            We would like to hear from you
          </h2>
          <div className="text-xs lg:text-sm flex flex-col gap-8 mt-4">
            <div className="flex items-center gap-4">
              <img src={NewPhone} alt="" width={50} />
              <div className="flex flex-col gap-2 justify-between">
                <p className="font-medium">Message or Call us via</p>
                <div className="flex">
                  <a
                    href="tel:+234 702 5640 071"
                    className="underline text-evergreen70 pr-2"
                  >
                    +234 702 5640 071
                  </a>
                  <div className="text-[#09090966] w-[4px] h-[20px]">|</div>
                  <a
                    href="tel:+234 702 5640 071"
                    className="underline text-evergreen70 pl-2"
                  >
                    +234 702 5640 071
                  </a>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <img src={NewMail} alt="" width={50} />
              <div className="flex flex-col justify-between gap-2">
                <p className="font-medium">Email</p>

                <span className="underline text-evergreen70">
                  <a href="mailto:infowealth@meristemng.com">
                    infowealth@meristemng.com
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <form className=" flex flex-col gap-4 lg:w-[620px] bg-evergreen60 p-4 lg:p-10 rounded-xl border border-[#55008033]">
          <Input
            label="Fullname"
            value={fullname}
            onChange={(e) =>
              setMailContent((prev) => ({
                ...prev,
                fullname: e.target.value,
              }))
            }
          />
          <Input
            label="Phone number"
            type="number"
            value={phone}
            onChange={(e) =>
              setMailContent((prev) => ({
                ...prev,
                phone: e.target.value,
              }))
            }
          />
          <Input
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) =>
              setMailContent((prev) => ({ ...prev, email: e.target.value }))
            }
          />
          <TextArea
            label="Message"
            value={body}
            onChange={(e) =>
              setMailContent((prev) => ({ ...prev, body: e.target.value }))
            }
          />

          <LinkButton
            href={generateMailtoLink()}
            variant="primary"
            className="rounded-full text-whitishGray w-28 text-center text-xs"
          >
            Submit
          </LinkButton>
        </form>
      </div>
    </Section>
  );
};

export { GetInTouch };
