import { Section } from "../../components";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  LogoColored,
  TwitterIcon,
} from "../../assets";

const Footer = () => {
  return (
    <Section
      bgClassName=" lg:px-14 border-t border-[#154D341A]"
      sectionClassName="flex flex-col lg:flex-row gap-4 justify-between items-center px-14 pt-2 pb-4  text-[#090909CC] text-xs lg:text-[14px]"
    >
      <div>
        <img src={LogoColored} alt="" className="w-28 lg:w-auto" />
      </div>

      <p>© 2024 Evergreen. All rights reserved.</p>

      <div className="flex items-center gap-2">
        <a
          href="https://facebook.com/meristemnigeria"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={FacebookIcon} alt="" width={30} />
        </a>
        <a
          href="https://twitter.com/meristemnigeria"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={TwitterIcon} alt="" width={30} />
        </a>
        <a
          href="https://instagram.com/meristemnigeria"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={InstagramIcon} alt="" width={30} />
        </a>
        <a
          href="https://www.linkedin.com/company/meristem-securities-limited/posts/?feedView=all"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LinkedinIcon} alt="" width={30} />
        </a>
      </div>
    </Section>
  );
};

export { Footer };
