import React from "react";
import { Section } from "../../../components";
import { About1, About2, AboutHeroMobile, AboutPattern } from "../../../assets";

const Hero = () => {
  return (
    <>
      <Section
        bgClassName="relative !bg-[#021006] lg:px-14 min-h-[500px]  md:min-h-[700px]"
        sectionClassName="pt-40 lg:pt-[200px]"
      >
        <img src={AboutPattern} alt="" className="absolute right-0 top-0" />
        <div className="flex justify-between items-end">
          <h1
            className="text-3xl text-whitishGray lg:text-[60px] uppercase font-medium max-w-[986px] lg:leading-[73.26px]"
            data-aos="fade-down"
          >
            Evergreen by Meristem: Where Your Legacy Meets Peace of Mind
          </h1>
        </div>

        <div className="sm:max-h-96 overflow-hidden w-[500px] sm:w-[95%] sm:max-w-[1320px]  absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-1/2 rounded-3xl">
          <div className="hidden sm:grid sm:grid-cols-2">
            <img src={About2} alt="" className=" sm:h-full sm:w-full" />
            <img
              src={About1}
              alt=""
              className=" sm:h-full sm:w-full object-contain"
            />
          </div>

          <div className="flex justify-center items-center sm:hidden  mx-auto">
            <img src={AboutHeroMobile} alt="" className="max-w-[370px]" />
          </div>
        </div>
      </Section>
      <Section>
        <div className="pt-[200px] lg:pt-72 mb-16 max-w-[700px] mx-auto">
          <h2 className=" my-4" data-aos="fade-up">
            about Evergreen
          </h2>
          <div className="flex flex-col gap-4" data-aos="zoom-in text-[17px]">
            <p>
              Evergreen by Meristem exists to help you design a customized
              retirement and estate plan that brings you happiness and peace of
              mind.
            </p>
            <p>
              This is done by incorporating all assets and liabilities that you
              may have accumulated before retirement to design a personalized
              combo plan that helps you achieve your personal goals ranging from
              financial obligations, social life, hobbies and living conditions
              after retirement, as well as help grow, preserve and transfer your
              wealth
            </p>
            <p>
              Evergreen by Meristem desires to help take care of your personal
              needs, ensure that you enjoy a fulfilling lifestyle after
              retirement while creating an efficient estate plan for a seamless
              wealth transfer.
            </p>
            <p>
              Ultimately, the goal of Evergreen by Meristem is to ensure that
              you have the benefit of an efficient and fulfilling retirement
              plan and can seamlessly transfer your assets to your
              beneficiaries.
            </p>
          </div>
        </div>
      </Section>
    </>
  );
};

export { Hero };
