interface ProgressTypes {
  progress: "start" | 1 | 2;
}

const ProgressBar: React.FC<ProgressTypes> = ({ progress }) => {
  const getWidth = () => {
    if (progress === "start") return "w-1";
    if (progress === 1) return "w-2/5";
    return "w-4/5";
  };

  const isDisabled = progress === "start";

  return (
    <div className="w-full mt-10 text-xs">
      <p
        className={`mb-2 ${isDisabled ? "text-[#09090999]" : "text-gray-700"}`}
      >
        Step {progress === "start" ? 1 : progress} of 2
      </p>
      <div
        className={`h-1 bg-[#0909090D] rounded-full overflow-hidden ${
          isDisabled ? "opacity-50" : ""
        }`}
      >
        <div
          className={`h-full bg-[#550080] rounded-full transition-all duration-500 ease-out ${getWidth()}`}
        ></div>
      </div>
    </div>
  );
};

export { ProgressBar };
