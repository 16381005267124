import React, { useState } from "react";
import { Heading } from "../heading";
import { Button, Input, LinkButton, Section } from "../../../components";
import { ProgressBar } from "../progressBar";
import { Routes, ScrollToTop } from "../../../router";
import toast from "react-hot-toast";
import { OptionType, SelectInput } from "../../../components/select-input";
import {
  incomeOptions,
  maritalStatusOptions,
  religionOptions,
  statesAndCapitals,
} from "../../../lib/data";

interface UserInfoType {
  firstname: string;
  middlename: string;
  lastname: string;
  dob: string;
  spouseFirstName: string;
  spouseLastName: string;
  homeAddress: string;
  phone: string;
  stateOfOrigin: OptionType;
  religion: OptionType;
  maritalStatus: OptionType;
  noOfChildren: string;
  noOfDependencies: string;
  assets: string;
  totalValueOfAssets: OptionType;
  expectedRetirementAge: string;
  lifeExpectancyPostRetire: string;
  currentAnnualSalary: OptionType;
  estimatedAnnualExpense: OptionType;
  hasConcretePlan: OptionType;
  estatePlanningObj: string;
  willAppointExecutor: OptionType;
}

const initInfo: UserInfoType = {
  firstname: "",
  middlename: "",
  lastname: "",
  dob: "",
  spouseFirstName: "",
  spouseLastName: "",
  homeAddress: "",
  phone: "",
  stateOfOrigin: { label: "", value: "" },
  religion: { label: "", value: "" },
  maritalStatus: { label: "", value: "" },
  noOfChildren: "",
  noOfDependencies: "",
  assets: "",
  totalValueOfAssets: { label: "", value: "" },
  expectedRetirementAge: "",
  lifeExpectancyPostRetire: "",
  currentAnnualSalary: { label: "", value: "" },
  estimatedAnnualExpense: { label: "", value: "" },
  hasConcretePlan: { label: "", value: "" },
  estatePlanningObj: "",
  willAppointExecutor: { label: "", value: "" },
};
interface UserInfoContextType {
  details: UserInfoType;
  handleUpdateDetails: (info: string, value: string | OptionType) => void;
  handleSetActiveStep: (step: 1 | 2) => void;
}

const assetOptions = [
  "Mortgage/Real Estate",
  "Shares",
  "Bank Account/Pension",
  "Autos",
  "Power of Attorney",
  "Funds /Fixed income Investments",
  "Digital Assets",
  "Intellectual property, Trademark, Copyright, Arts",
  "Other",
];

const StepsUI = () => {
  const [details, setDetails] = useState<UserInfoType>(initInfo);
  const [activeStep, setActiveStep] = useState<1 | 2>(1);
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);

  const handleUpdateDetails = (info: string, value: string | OptionType) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [info]: typeof value === "object" && "value" in value ? value : value,
    }));
  };

  const handleSetActiveStep = (step: 1 | 2) => {
    setActiveStep(step);
  };

  const handleAssetChange = (asset: string) => {
    setSelectedAssets((prevAssets) => {
      if (prevAssets.includes(asset)) {
        return prevAssets.filter((a) => a !== asset);
      } else {
        return [...prevAssets, asset];
      }
    });
  };

  const assetsString =
    selectedAssets.length > 1 ? selectedAssets.join(", ") : selectedAssets[0];

  return (
    <Section sectionClassName="lg:w-[872px] text-blackBg">
      <Heading />
      <ProgressBar progress={activeStep} />
      <div className="mt-4 rounded-2xl overflow-hidden mb-20 bg-evergreen60 py-5 lg:py-11 px-4 lg:px-16 flex flex-col gap-4 border border-[#55008033]">
        {activeStep === 1 ? (
          <StepOne
            details={details}
            handleUpdateDetails={handleUpdateDetails}
            handleSetActiveStep={handleSetActiveStep}
          />
        ) : (
          <StepTwo
            details={details}
            handleUpdateDetails={handleUpdateDetails}
            handleSetActiveStep={handleSetActiveStep}
            selectedAssets={selectedAssets}
            assets={assetsString}
            handleAssetChange={handleAssetChange}
          />
        )}
      </div>
    </Section>
  );
};

export { StepsUI };

const StepOne: React.FC<UserInfoContextType> = ({
  details,
  handleUpdateDetails,
  handleSetActiveStep,
}) => {
  // List of fields to validate
  const fieldsToCheck: (keyof UserInfoType)[] = [
    "firstname",
    "middlename",
    "lastname",
    "dob",
    "spouseFirstName",
    "spouseLastName",
    "homeAddress",
    "phone",
    "stateOfOrigin",
    "religion",
    "maritalStatus",
    "noOfChildren",
    "noOfDependencies",
  ];

  // State for invalid fields
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  // Validation logic
  const handleValidation = () => {
    const emptyFields = fieldsToCheck.filter((field) => !details[field]);
    setInvalidFields(emptyFields);
    return emptyFields.length === 0;
  };

  // Handle continue button click
  const handleContinue = () => {
    if (handleValidation()) {
      handleSetActiveStep(2);
    } else {
      toast.error("Please fill in the required fields");
    }
  };

  // Function to apply conditional styles based on invalid fields
  const isInvalid = (field: string) => invalidFields.includes(field);
  return (
    <form className="">
      <ScrollToTop />
      <p className="uppercase mb-6 font-medium text-2xl lg:text-lg text-blackBg">
        BIOGRAPHIC INFORMATION
      </p>

      <div className="flex flex-col gap-6">
        <div className="grid lg:grid-cols-2 gap-6">
          <Input
            label="First Name"
            value={details.firstname}
            onChange={(e) => handleUpdateDetails("firstname", e.target.value)}
            className={isInvalid("firstname") ? "border-red" : ""}
            required={isInvalid("firstname")}
          />
          <Input
            label="Middle Name"
            value={details.middlename}
            onChange={(e) => handleUpdateDetails("middlename", e.target.value)}
            className={isInvalid("middlename") ? "border-red" : ""}
            required={isInvalid("middlename")}
          />
        </div>

        <div className="grid lg:grid-cols-2 gap-6">
          <Input
            label="Last Name"
            value={details.lastname}
            onChange={(e) => handleUpdateDetails("lastname", e.target.value)}
            className={isInvalid("lastname") ? "border-red" : ""}
            required={isInvalid("lastname")}
          />
          <Input
            label="Date of Birth"
            type="date"
            parentClassName="relative"
            value={details.dob}
            onChange={(e) => handleUpdateDetails("dob", e.target.value)}
            className={isInvalid("dob") ? "border-red" : ""}
            required={isInvalid("dob")}
          />
        </div>

        <div className="grid lg:grid-cols-2 gap-6">
          <Input
            label="Spouse First Name"
            value={details.spouseFirstName}
            onChange={(e) =>
              handleUpdateDetails("spouseFirstName", e.target.value)
            }
            className={isInvalid("spouseFirstName") ? "border-red" : ""}
            required={isInvalid("spouseFirstName")}
          />
          <Input
            label="Spouse's Last Name"
            value={details.spouseLastName}
            onChange={(e) =>
              handleUpdateDetails("spouseLastName", e.target.value)
            }
            className={isInvalid("spouseLastName") ? "border-red" : ""}
            required={isInvalid("spouseLastName")}
          />
        </div>
        <Input
          label="Home Address"
          value={details.homeAddress}
          onChange={(e) => handleUpdateDetails("homeAddress", e.target.value)}
          className={isInvalid("homeAddress") ? "border-red" : ""}
          required={isInvalid("homeAddress")}
        />

        <div className="grid lg:grid-cols-2 gap-6">
          <Input
            label="Telephone No"
            type="number"
            value={details.phone}
            onChange={(e) => handleUpdateDetails("phone", e.target.value)}
            className={isInvalid("phone") ? "border-red" : ""}
            required={isInvalid("phone")}
          />
          <SelectInput
            label="State of Origin"
            parentClassName="relative"
            className={isInvalid("stateOfOrigin") ? "border-red" : ""}
            required={isInvalid("stateOfOrigin")}
            placeholder="Select  option"
            options={statesAndCapitals}
            name="stateOfOrigin"
            selectedOption={details.stateOfOrigin}
            handleSelectChange={(value) =>
              value && handleUpdateDetails("stateOfOrigin", value)
            }
          />
        </div>
        <div className="grid lg:grid-cols-2 gap-6">
          <SelectInput
            label="Religion"
            parentClassName="relative"
            required={isInvalid("religion")}
            placeholder="Select  option"
            options={religionOptions}
            name="religion"
            selectedOption={details.religion}
            handleSelectChange={(value) =>
              value && handleUpdateDetails("religion", value)
            }
          />
          <SelectInput
            label="Marital Status"
            parentClassName="relative"
            required={isInvalid("maritalStatus")}
            placeholder="Select  option"
            options={maritalStatusOptions}
            name="maritalStatus"
            selectedOption={details.maritalStatus}
            handleSelectChange={(value) =>
              value && handleUpdateDetails("maritalStatus", value)
            }
          />
        </div>

        <p className="uppercase mb-6 font-medium text-2xl lg:text-lg text-blackBg">
          YOUR CHILDREN AND OTHER DEPENDENCE
        </p>
        <div className="grid lg:grid-cols-2 gap-6">
          <Input
            label="How many Children do you have?"
            type="number"
            value={details.noOfChildren}
            onChange={(e) =>
              handleUpdateDetails("noOfChildren", e.target.value)
            }
            className={isInvalid("noOfChildren") ? "border-red" : ""}
            required={isInvalid("noOfChildren")}
          />
          <Input
            label="How many Dependencies do you have?"
            type="number"
            value={details.noOfDependencies}
            onChange={(e) =>
              handleUpdateDetails("noOfDependencies", e.target.value)
            }
            className={isInvalid("noOfDependencies") ? "border-red" : ""}
            required={isInvalid("noOfDependencies")}
            tooltipContent="Your dependency is anyone asides your children who depend on you as a source of livelihood."
          />
        </div>
      </div>
      <div className="flex justify-between items-center pt-6">
        <LinkButton
          to={Routes.questionnaire}
          variant="transparent"
          className="rounded-full text-evergreen70 min-w-24  text-center text-xs bg-[#0909090D]"
        >
          Back
        </LinkButton>
        <Button
          onClick={handleContinue}
          variant="primary"
          className="rounded-full text-whitishGray w-32 text-center text-xs disabled:bg-opacity-50 disabled:cursor-not-allowed"
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

const StepTwo: React.FC<
  UserInfoContextType & {
    assets: string;
    selectedAssets: string[];
    handleAssetChange: (asset: string) => void;
  }
> = ({
  details,
  handleUpdateDetails,
  handleSetActiveStep,
  assets,
  selectedAssets,
  handleAssetChange,
}) => {
  // List of fields to validate
  const fieldsToCheck: (keyof UserInfoType)[] = [
    "totalValueOfAssets",
    "expectedRetirementAge",
    "lifeExpectancyPostRetire",
    "currentAnnualSalary",
    "estimatedAnnualExpense",
    "hasConcretePlan",
    "estatePlanningObj",
    "willAppointExecutor",
  ];

  // State for invalid fields
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  // Validation logic
  const handleValidation = () => {
    const emptyFields = fieldsToCheck.filter((field) => !details[field]);
    setInvalidFields(emptyFields);
    return emptyFields.length === 0;
  };

  // Handle continue button click
  const handleContinue = () => {
    if (handleValidation()) {
      setIsFormValid(true); // Show the mailto link
      toast.success("Form validated! Click 'Submit' again to send the email.");
    } else {
      toast.error("Please fill in the required fields");
    }
  };

  // Function to apply conditional styles based on invalid fields
  const isInvalid = (field: string) => invalidFields.includes(field);

  const {
    firstname,
    middlename,
    lastname,
    dob,
    spouseFirstName,
    spouseLastName,
    homeAddress,
    phone,
    stateOfOrigin,
    religion,
    maritalStatus,
    noOfChildren,
    noOfDependencies,
    totalValueOfAssets,
    expectedRetirementAge,
    lifeExpectancyPostRetire,
    currentAnnualSalary,
    estimatedAnnualExpense,
    hasConcretePlan,
    estatePlanningObj,
    willAppointExecutor,
  } = details;

  const body = `
BIOGRAPHY:
Name: ${firstname} ${middlename} ${lastname}

Date of birth: ${dob}

Address: ${homeAddress}

Spouse's name: ${spouseFirstName} ${spouseLastName}

Phone: ${phone}

State of Origin: ${stateOfOrigin.value}

Religion: ${religion.value}

Marital Status: ${maritalStatus.value}

CHILDREN AND DEPENDENTS DETAILS:
Number of Children: ${noOfChildren}

Number of Dependencies: ${noOfDependencies}

FAMILY ASSETS:
Assets: ${assets}

Total value of Assets: ${totalValueOfAssets.value}

ESTATE PLANNING:
Expected retirement Age: ${expectedRetirementAge}

Life expectancy post-retirement: ${lifeExpectancyPostRetire}

Current Annual Salary: ${currentAnnualSalary.value}

Estimated annual Expense in retirement: ${estimatedAnnualExpense.value}

Do you have any concrete plan on how to transfer your Assets to your Beneficiaries?: ${hasConcretePlan.value}

Personal and specific estate planning objectives: ${estatePlanningObj}

Would you wish to appoint a Protector/Executor of your Estate Plan?:${willAppointExecutor.value}


Proof-read this before sending, Thank you.
`;

  const subject = `${firstname} ${middlename} ${lastname}`;

  const generateMailtoLink = () => {
    return `mailto:${"retirementplanning@meristemng.com"}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  return (
    <div>
      <ScrollToTop />
      <p className="uppercase mb-6 font-medium text-2xl lg:text-lg text-blackBg ">
        PERSONAL AND FAMILY FINANCIAL ASSETS
      </p>

      <div className="flex flex-col gap-6">
        <div>
          <p className="mb-2 font-bold">
            Choose the class of assets that you currently have?
          </p>
          {assetOptions.map((asset) => (
            <label key={asset} className="flex items-center space-x-2 mb-3">
              <Input
                type="checkbox"
                checked={selectedAssets.includes(asset)}
                onChange={() => handleAssetChange(asset)}
                parentClassName="gap-1"
              />
              <span>{asset}</span>
            </label>
          ))}
        </div>

        <SelectInput
          label="What is the Total Value of your Assets"
          parentClassName="relative"
          required={isInvalid("totalValueOfAssets")}
          placeholder="Select option"
          options={incomeOptions}
          name="totalValueOfAssets"
          selectedOption={details.totalValueOfAssets}
          handleSelectChange={(value) =>
            value && handleUpdateDetails("totalValueOfAssets", value)
          }
        />

        <p className="uppercase mb-6 font-medium text-2xl lg:text-lg text-blackBg ">
          ESTATE PLANNING
        </p>
        <div className="grid lg:grid-cols-2 gap-6">
          <Input
            label="Expected retirement Age"
            type="number"
            value={details.expectedRetirementAge}
            onChange={(e) =>
              handleUpdateDetails("expectedRetirementAge", e.target.value)
            }
            required={isInvalid("expectedRetirementAge")}
          />
          <Input
            label="Life expectancy post-retirement"
            type="number"
            value={details.lifeExpectancyPostRetire}
            onChange={(e) =>
              handleUpdateDetails("lifeExpectancyPostRetire", e.target.value)
            }
            required={isInvalid("lifeExpectancyPostRetire")}
          />
        </div>

        <div className="grid lg:grid-cols-2 gap-6">
          <SelectInput
            label="Current Annual Salary"
            parentClassName="relative"
            required={isInvalid("currentAnnualSalary")}
            placeholder="Select option"
            options={incomeOptions}
            name="currentAnnualSalary"
            selectedOption={details.currentAnnualSalary}
            handleSelectChange={(value) =>
              value && handleUpdateDetails("currentAnnualSalary", value)
            }
          />

          <SelectInput
            label="Estimated annual Expense in retirement"
            parentClassName="relative"
            required={isInvalid("estimatedAnnualExpense")}
            placeholder="Select option"
            options={incomeOptions}
            name="estimatedAnnualExpense"
            selectedOption={details.estimatedAnnualExpense}
            handleSelectChange={(value) =>
              value && handleUpdateDetails("estimatedAnnualExpense", value)
            }
          />
        </div>
        <SelectInput
          label="Do you have any concrete plan on how to transfer your Assets to your Beneficiaries?"
          parentClassName="relative"
          required={isInvalid("hasConcretePlan")}
          placeholder="Select option"
          options={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
          name="hasConcretePlan"
          selectedOption={details.hasConcretePlan}
          handleSelectChange={(value) =>
            value && handleUpdateDetails("hasConcretePlan", value)
          }
        />
        <div className="grid lg:grid-cols-2 gap-6">
          <Input
            label="What are your personal and specific estate planning objectives?"
            value={details.estatePlanningObj}
            onChange={(e) =>
              handleUpdateDetails("estatePlanningObj", e.target.value)
            }
            tooltipContent="E.g. financial dependence for children, charitable causes, management of asset in the event of incapacitation or demise etc."
            required={isInvalid("estatePlanningObj")}
          />

          <SelectInput
            label="Would you wish to appoint a Protector or 
Executor?"
            parentClassName="relative"
            required={isInvalid("willAppointExecutor")}
            placeholder="Select option"
            options={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
            name="willAppointExecutor"
            selectedOption={details.willAppointExecutor}
            handleSelectChange={(value) =>
              value && handleUpdateDetails("willAppointExecutor", value)
            }
            tooltipContent="A Protector/Executor is the person who will ensure that your objectives are fulfilled in your absence or incapacitation."
          />
        </div>
      </div>

      <div className="flex justify-between items-center pt-6">
        <Button
          onClick={() => handleSetActiveStep(1)}
          variant="transparent"
          className="rounded-full text-evergreen70 min-w-24 text-center text-xs bg-[#0909090D]"
        >
          Back
        </Button>

        {!isFormValid ? (
          <Button
            onClick={() => handleContinue()}
            variant="primary"
            className="rounded-full text-whitishGray w-28 text-center text-xs"
          >
            Continue
          </Button>
        ) : (
          <LinkButton
            href={generateMailtoLink()}
            variant="primary"
            className="rounded-full text-whitishGray w-28 text-center text-xs"
          >
            Submit
          </LinkButton>
        )}
      </div>
    </div>
  );
};
