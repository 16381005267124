import { Link, useLocation } from "react-router-dom";
import { LinkButton, Section } from "../../components";
import { Routes } from "../../router";
import { useState } from "react";
import {
  CloseMenu,
  EvergreenLogo,
  HamburgerMenu,
  HamburgerMenuDark,
  LogoColored,
} from "../../assets";

export const Navlinks: {
  name: string;
  path: "home" | "about" | "faqs" | "questionnaire" | "contact";
}[] = [
  {
    name: "Home",
    path: "home",
  },
  {
    name: "About",
    path: "about",
  },

  {
    name: "FAQ  ",
    path: "faqs",
  },
  {
    name: "Contact",
    path: "contact",
  },
  {
    name: "Questionnaire",
    path: "questionnaire",
  },
];

const Navigation = () => {
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [addBg, setAddBg] = useState(false);

  const handleScroll = () => {
    setAddBg(window.scrollY > 80);
  };
  window.addEventListener("scroll", handleScroll);

  const navBg =
    addBg ||
    pathname === "/questionnaire" ||
    pathname === "/faqs" ||
    pathname === "/questionnaire-steps" ||
    pathname === "/questionnaire-completed";
  return (
    <Section
      bgClassName={`fixed w-full z-20 !px-0 lg:px-4 top-0 left-0 right-0 ${
        navBg ? "bg-[#f3eaf8] text-blackText" : "bg-none text-whitishGray"
      }`}
      sectionClassName={`  relative `}
    >
      <nav className={`lg:px-6`}>
        <section className="flex justify-between items-center p-3">
          <Link to={Routes.home}>
            <img
              src={navBg ? LogoColored : EvergreenLogo}
              alt="logo"
              className="w-28 lg:w-auto"
            />
          </Link>
          <aside className="hidden lg:flex gap-6">
            {Navlinks.map((link, i) => (
              <Link key={i} to={Routes[link.path]}>
                {link.name}
              </Link>
            ))}
          </aside>

          <aside className="hidden lg:flex items-center">
            {pathname === "/questionnaire" ||
            pathname === "/questionnaire-steps" ||
            pathname === "/questionnaire-completed" ? (
              <LinkButton
                variant="primary"
                to={Routes.contact}
                icon
                className="!text-whitishGray gap-4"
              >
                Contact
              </LinkButton>
            ) : (
              <LinkButton
                variant="primary"
                to={Routes.questionnaire}
                icon
                className="!text-whitishGray gap-4"
              >
                Questionnaire
              </LinkButton>
            )}
          </aside>
          <div className="lg:hidden" onClick={() => setMenuOpen(!menuOpen)}>
            <img
              src={navBg ? HamburgerMenuDark : HamburgerMenu}
              alt="menu"
              className="w-6 pt-1.5 cursor-pointer"
            />
          </div>
        </section>
        {menuOpen && (
          <div className="h-dvh bg-whitishGray absolute top-0 z-30 left-0 right-0 w-full text-blackText font-bold">
            <aside className="lg:hidden px-3 pt-20 flex flex-col justify-center items-center gap-12">
              <img
                src={CloseMenu}
                alt="close"
                className="absolute top-6 right-6 cursor-pointer"
                onClick={() => setMenuOpen(!menuOpen)}
              />
              {Navlinks.slice(0, 4).map((link, i) => (
                <Link
                  key={i}
                  to={Routes[link.path]}
                  onClick={() => setMenuOpen(false)}
                >
                  {link.name}
                </Link>
              ))}
              <LinkButton
                variant="primary"
                to={Routes.questionnaire}
                icon
                className="text-whitishGray gap-4 text-[15px]"
                onClick={() => setMenuOpen(false)}
              >
                Questionnaire
              </LinkButton>
            </aside>
          </div>
        )}
      </nav>
    </Section>
  );
};

export { Navigation };
