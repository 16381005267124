import React, { InputHTMLAttributes, useState } from "react";
import { TooltipIcon } from "../../assets";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  id?: string;
  type?: "text" | "number" | "email" | "checkbox" | "radio" | "file" | "date";
  error?: string;
  parentClassName?: string;
  tooltipContent?: string;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    onChange,
    value,
    className,
    parentClassName,
    type = "text",
    label,
    name,
    error,
    required,
    tooltipContent,
    ...rest
  } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className={`${
        type === "checkbox" ? "flex items-end" : "flex flex-col-reverse"
      } ${parentClassName}`}
    >
      <div className="flex flex-col">
        <input
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          className={`${className} ${
            type === "checkbox" ? "w-4 h-4 !rounded-xl" : ""
          } bg-evergreen60 py-1.5 px-3 lg:p-3 border border-borders rounded-lg accent-evergreen70`}
          {...rest}
        />
        <small
          className={`${required ? "text-red" : "text-evergreen60"} ${
            type === "checkbox" ? "hidden" : ""
          }`}
        >
          {" "}
          Required
        </small>
      </div>

      {label && (
        <label className="text-blackText font-medium flex items-center">
          {label}

          {/* Conditionally render the tooltip icon */}
          {tooltipContent && (
            <div
              className="relative ml-2"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              {/* Tooltip Icon */}
              <img
                src={TooltipIcon}
                alt="Info"
                className="w-5 h-5 cursor-pointer"
              />

              {/* Tooltip Content */}
              {showTooltip && (
                <div className="mb-4 absolute bottom-full -right-4 w-[250px] p-3 text-xs bg-evergreen70 text-paragraphWhite rounded-lg">
                  <div className="relative ">
                    <div className="absolute w-0 h-0 border-x-8 border-t-8 border-t-evergreen70 border-x-transparent -bottom-[19px] right-2" />
                    <p>{tooltipContent}</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </label>
      )}

      {error && <p className="text-red">{error}</p>}
    </div>
  );
};

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  id?: string;
  error?: string;
}

const TextArea: React.FC<TextAreaProps> = (props) => {
  const { onChange, value, className, label, name, error, ...rest } = props;

  return (
    <div className="flex flex-col">
      {label && (
        <label className="text-blackText font-medium pl-1 pb-1">{label} </label>
      )}
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        maxLength={5000}
        className={`${className} bg-evergreen60 p-4 border border-borders rounded-lg`}
        {...rest}
      />

      {error && <p className="text-red text-xs">{error}</p>}
    </div>
  );
};

export { Input, TextArea };
