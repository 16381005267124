import React from "react";
import { Heading } from "../heading";
import { Routes } from "../../../router";
import { LinkButton, Section } from "../../../components";
import { ProgressBar } from "../progressBar";

const Purpose = () => {
  return (
    <Section sectionClassName="lg:w-[872px] text-blackBg">
      <Heading />
      <ProgressBar progress={"start"} />
      <div
        className="mt-4 rounded-2xl overflow-hidden mb-20  border border-[#55008033]"
        data-aos="zoom-in"
      >
        <p className="uppercase bg-evergreen70 py-6 text-center font-medium text-base lg:text-2xl text-whitishGray">
          The purpose of this questionnaire.
        </p>

        <div className="bg-evergreen60 py-5 px-4 lg:px-20 flex flex-col gap-4">
          <p>
            <strong>MERISTEM WEALTH MANAGEMENT LIMITED (MWML)</strong> will use
            the information you provide in this questionnaire:
          </p>

          <ol className="list-decimal list-outside pl-6 space-y-4">
            <li className="pl-2">
              To help you organize personal and financial information so that we
              can assess your estate plans and evaluate whether changes are
              desired or required.
            </li>
            <li className="pl-2">
              The details provided in this questionnaire will be used to
              generate an initial proposal for your Evergreen plan. We crave
              your patience to thoroughly fill in the required information.
            </li>
          </ol>
          <p>
            The information you provide must be as accurate as possible. If you
            are uncertain about exact information, please give your best
            assessment. We recognize that this questionnaire is an intrusive
            document and may require the divulgence of very private details and
            wish to reassure you that the information revealed or given in this
            questionnaire would be kept confidential unless you authorize or
            request its release to others.
          </p>
          <p>
            Please be advised that the more complete the information is, the
            better it will equip you and MWML throughout the planning process to
            come up with the best possible plan.
          </p>
          <p>
            State the names requested below exactly as you want them to appear
            in your account.
          </p>

          <LinkButton
            to={Routes.questionnaire_steps}
            variant="primary"
            className="rounded-full text-whitishGray w-32 text-center text-xs mt-3 mb-8"
          >
            Continue
          </LinkButton>
        </div>
      </div>
    </Section>
  );
};

export { Purpose };
