import { ReactNode } from "react";
import { FaqUI } from "../../modules";

export interface FaqsTypes {
  question: string;
  answer: string | ReactNode;
}

const faqs: FaqsTypes[] = [
  {
    question: "What is Evergreen by Meristem?",
    answer:
      "Evergreen by Meristem is a specialized service designed to assist Mass Affluent & High Net-worth individuals to develop an actionable plan geared at accumulating & preserving sufficient cashflow required to maintain a desired pre & post-retirement financial/lifestyle status as well as transferring accumulated wealth to beneficiaries This service covers Retirement planning and Estate planning for subscribers.",
  },
  {
    question:
      "Is there a minimum amount to subscribe to Evergreen by Meristem?",
    answer:
      "The minimum initial contribution to the portfolio is N20 million, subsequent contributions are made in line with the portfolio design and objective of the subscriber.",
  },
  {
    question: "Who can subscribe to the Evergreen by Meristem?",
    answer:
      "Anyone can subscribe to Evergreen, for themselves or on behalf of another party.",
  },
  {
    question: "What benefit is there to subscribing to evergreen products?",
    answer: (
      <ul>
        <li>Takes away the complication of fragmented planning</li>
        <li>Customized Retirement Plan</li>
        <li>Efficient Wealth Preservation & Transfer Plan (Estate Planning)</li>
        <li>
          Professional Management of Assets/liabilities (where applicable)
        </li>
        <li>
          Seamless and effective structure to transfer assets to loved ones to
          maximize value and minimize tax.
        </li>
      </ul>
    ),
  },
  {
    question:
      " How frequently can I receive cash flow from my Evergreen portfolio?",
    answer:
      "Cash flow from the portfolio is based on the structure of the individual portfolios and is decided by the subscriber when the portfolio is being created. This can be changed at any time by the subscriber, subject to the portfolio realities.",
  },
  {
    question: "What kind of investments will my funds be invested in?",
    answer:
      "The specific Investments made in the portfolio depend on the portfolio asset allocation, which depends on several factors including portfolio risk profile, horizon, liquidity needs, etc. This is therefore unique to each subscriber.",
  },
  {
    question: "What service does Evergreen by Meristem offer?",
    answer: "Retirement Planning, Estate Planning, Health Care.",
  },
  {
    question: "Is there a minimum holding period?",
    answer: "The minimum holding period is 1 year",
  },
  {
    question: "Can I borrow against my investment with Evergreen",
    answer: "Yes, you can use the portfolio as collateral for loans",
  },
  {
    question: " Does Evergreen offer different risk tolerance portfolios?",
    answer:
      "Yes, Evergreen portfolios are unique and so have different risk tolerance in line with the risk profile of the subscriber",
  },
  {
    question: "Can I negotiate the fees associated with Evergreen?",
    answer:
      "What are the applicable fees/charges? The fees are competitive, they are not fixed but calculated based on portfolio performance",
  },
  {
    question:
      "Does Evergreen offer online access to my account information and statements?",
    answer:
      "Not at the moment, however, portfolio reports will be sent to subscribers monthly",
  },
  {
    question:
      "What happens to my plan if my net asset value falls below the NGN 20 million minimum?",
    answer:
      "The reason for the drop in the portfolio value will be assessed and a resolution to grow the portfolio will be determined.",
  },
  {
    question:
      "Are there any alternative financial planning options for individuals who don’t meet the minimum investment threshold?",
    answer:
      "Yes, we have several other financial planning options within the Meristem group including investments in our mutual funds, Exchange-traded funds (ETF), equities, real estate services, and several other fixed-income instruments.",
  },
  {
    question:
      "Can I receive a complimentary consultation to discuss my eligibility for Evergreen?",
    answer:
      "Yes, we are happy to have a conversation with you, please reach out to us at wealthmanagement@meristemng.com and we will be sure to speak with you.",
  },
  {
    question: "How do I go about subscribing to Evergreen by Meristem?",
    answer:
      "Please fill out the questionnaire linked on this website or send an email to wealthmanagement@meristemng.com,(place link to questionnaire here).",
  },
];
const Faq = () => {
  return (
    <>
      <FaqUI faqs={faqs} />
    </>
  );
};

export { Faq };
