import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  AnchorHTMLAttributes,
} from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "../../assets";

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant: "primary" | "transparent";
  icon?: boolean;
}

interface LinkButtonProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  variant: "primary" | "transparent";
  icon?: boolean;
  to?: string;
}

// Button component
const Button = (props: ButtonProps) => {
  const {
    onClick,
    variant = "transparent",
    children,
    className,
    icon,
    ...rest
  } = props;
  return (
    <button
      {...rest}
      className={`${className} font-medium h-11 lg:h-[54px] min-w-[100px] rounded-full flex justify-center items-center relative px-6 transition-all  text-xs lg:text-[15px] ${
        variant === "primary" ? "bg-evergreen70" : ""
      }`}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
    >
      {children}
      {icon && (
        <span className="flex justify-center items-center">
          <img src={ArrowRight} alt="arrow" />
        </span>
      )}
    </button>
  );
};

// LinkButton component
const LinkButton = (props: LinkButtonProps) => {
  const { href, to, variant, children, className, icon, ...rest } = props;
  return (
    <>
      {to ? (
        <Link
          to={to}
          {...rest}
          className={`${className} font-medium h-11 lg:h-[54px] min-w-[100px] lg:min-w-[166px] rounded-full flex justify-center items-center px-6 transform transition-all hover:scale-[.98] cursor-pointer text-xs lg:text-[15px] ${
            variant === "primary" ? "bg-evergreen70" : ""
          }`}
        >
          {children}
          {icon && (
            <span className="flex justify-center items-center">
              <img src={ArrowRight} alt="arrow" width={16} />
            </span>
          )}
        </Link>
      ) : (
        <a
          href={href}
          {...rest}
          className={`${className} font-medium h-11 lg:h-[54px] min-w-[100px] lg:min-w-[166px] rounded-full flex justify-center items-center px-3 lg:px-6 transform text-xs lg:text-[14px] transition-all hover:scale-[.98] cursor-pointer  ${
            variant === "primary" ? "bg-evergreen70" : ""
          }`}
        >
          {children}
          {icon && (
            <span className="flex justify-center items-center">
              <img src={ArrowRight} alt="arrow" />
            </span>
          )}
        </a>
      )}
    </>
  );
};

export { LinkButton, Button };
