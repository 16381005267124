import { Hero } from './hero';

const AboutUI = () => {
  return (
    <>
      <Hero />
    </>
  );
};

export { AboutUI };
