import { GetInTouch } from "./getInTouch";
import { Hero } from "./hero";
import { WhyMerigame } from "./whyEvergreen";

const HomeUI = () => {
  return (
    <>
      <Hero />
      <WhyMerigame />
      <GetInTouch />
    </>
  );
};

export { HomeUI };
