import { ReactNode, useState } from "react";
import { Section } from "../../components";
import { FaqsTypes } from "../../pages";

interface FaqProps {
  faqs: FaqsTypes[];
}
const FaqUI: React.FC<FaqProps> = ({ faqs }) => {
  const [curOpen, setCurOpen] = useState<number | null>(null);

  const handleOpen = (num: number | null) => {
    setCurOpen(num);
  };
  return (
    <Section bgClassName="" sectionClassName="pt-[120px] pb-4">
      <h1
        className="text-3xl my-10 text-center mx-auto lg:text-5xl uppercase font-bold max-w-[500px]"
        data-aos="fade-down"
      >
        Frequently <br /> asked questions
      </h1>{" "}
      <div className="max-w-[872px] mx-auto pr-4" data-aos="flip-right">
        {faqs.map((item, i) => (
          <AccordionItem
            key={i}
            curOpen={curOpen}
            onOpen={handleOpen}
            num={i}
            {...item}
          >
            {item.answer}
          </AccordionItem>
        ))}
      </div>
    </Section>
  );
};

export { FaqUI };
interface AccordionItems {
  num: number;
  children: ReactNode;
  question: string;
  curOpen: number | null;
  onOpen: (num: number | null) => void;
}

const AccordionItem = ({
  num,
  question,
  curOpen,
  onOpen,
  children,
}: AccordionItems) => {
  const isOpen = num === curOpen;

  const handleToggle = () => {
    onOpen(isOpen ? null : num);
  };

  return (
    <>
      <div
        onClick={handleToggle}
        className={`rounded-md overflow-hidden text-xs lg:text-[14px] my-2 border-[1.5px] bg-evergreen60 ${
          isOpen ? "border-evergreen70" : "border-none"
        }`}
      >
        <div
          className={`flex relative justify-between font-semibold text-blackBg cursor-pointer`}
        >
          <p className="p-4 lg:p-4 max-w-[280px] sm:max-w-[600px] lg::max-w-none">
            {question}
          </p>

          <span
            className={` absolute h-full top-0 right-0 flex justify-center items-center w-14 lg:w-[88px] bg-[#EDDBF7]`}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22.1992C17.5 22.1992 22 17.6992 22 12.1992C22 6.69922 17.5 2.19922 12 2.19922C6.5 2.19922 2 6.69922 2 12.1992C2 17.6992 6.5 22.1992 12 22.1992Z"
                fill="#550080"
                stroke="#550080"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 12.1992H16"
                stroke="#FEFBFF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 16.1992V8.19922"
                stroke="#FEFBFF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
      <div
        className={`overflow-hidden transition-all duration-300 px-4 font-normal ${
          isOpen ? "max-h-[1000px]" : "max-h-0"
        }`}
      >
        {children}
      </div>
    </>
  );
};
