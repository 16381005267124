import { PathRouteProps } from "react-router-dom";
import { Routes } from "./routes";
import {
  About,
  CompletedQuestions,
  Faq,
  Home,
  QuestionnairePurpose,
  Steps,
} from "../pages";
import { Layout } from "../layout";

// Route Builder Item Props
export interface RouteBuilderItem extends PathRouteProps {
  Layout?: React.FC<any>; // If you wish to add a layout to the page
  Element: React.FC;
  props?: any;
}

/**
 * ROUTE BUILDER
 *
 * ===============================================
 *
 * This is a list of all our application routes.
 *
 * A single item on this list contains the necessary Route props needed by React Router to do it's job.
 *
 * If you wish to add a new route to the application,
 * just fulfill all the necessary props needed by the RouteBuilder item. Ciao!
 *
 */
export const RouteBuilder: RouteBuilderItem[] = [
  {
    path: Routes.home,
    Element: Home,
    Layout,
  },
  {
    path: Routes.faqs,
    Element: Faq,
    Layout,
  },
  {
    path: Routes.about,
    Element: About,
    Layout,
  },

  // Questionnaire
  {
    path: Routes.questionnaire,
    Element: QuestionnairePurpose,
    Layout,
  },
  {
    path: Routes.questionnaire_steps,
    Element: Steps,
    Layout,
  },
  {
    path: Routes.questionnaire_completed,
    Element: CompletedQuestions,
    Layout,
  },

  {
    path: Routes.contact,
    Element: Home,
    Layout,
  },
  {
    path: "*",
    Element: Home,
    Layout,
  },
];
