import { HeroEffective, HeroSeamless } from "../../../assets";
import { LinkButton, Section } from "../../../components";
import { Routes } from "../../../router";

const Hero = () => {
  return (
    <Section
      bgClassName="heroHome relative lg:px-14"
      sectionClassName="text-whitishGray pt-[200px] pb-4 "
    >
      <div>
        <h1
          className="text-3xl lg:text-[60px] uppercase font-medium max-w-[632px] lg:leading-[73.26px]"
          data-aos="zoom-in"
        >
          live well and leave more behind with Evergreen
        </h1>
        <p
          className="max-w-[571px] text-paragraphWhite my-3"
          data-aos="fade-left"
        >
          A service that assists investors with their retirement and wealth
          transfer. Seamless and effective structure to transfer assets to loved
          ones while maximising value and minimising tax.​
        </p>
        <div className="flex items-center gap-2 mt-6">
          <LinkButton
            variant="primary"
            to={Routes.contact}
            icon
            className="min-w-[45%] gap-2 whitespace-nowrap"
          >
            Contact Us
          </LinkButton>
          <LinkButton
            variant="transparent"
            to={Routes.about}
            className="bg-whitishGray/20 backdrop-blur-sm text-whitishGray min-w-[45%] whitespace-nowrap"
          >
            Learn more
          </LinkButton>
        </div>
      </div>

      <div className="bg-blackBg w-[95%] max-w-[1320px] flex flex-col lg:flex-row py-6 px-4 md:p-10  xl:p-[60px] gap-5 absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-1/2 rounded-3xl">
        <div className="flex-1">
          <div className="flex flex-col lg:flex-row gap-5">
            <img src={HeroSeamless} alt="" className="w-[50px] h-[50px]" />
            <div>
              <p className="text-whitishGray uppercase font-medium">
                Seamless structure
              </p>
              <p className="text-paragraphWhite">
                We specialize in crafting strategies that maximize your
                retirement savings while ensuring your estate is managed &
                transferred according to your wishes.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full h-px lg:w-px lg:h-[76px] bg-[#FFFFFF1A] self-stretch m-auto"></div>
        <div className="flex-1">
          <div className="flex  flex-col lg:flex-row gap-5">
            <img src={HeroEffective} alt="" className="w-[50px] h-[50px]" />
            <div>
              <p className="text-whitishGray uppercase font-medium">
                Effective strategies
              </p>
              <p className="text-paragraphWhite">
                We offer personalized & effective strategies tailored to meet
                your financial situation & retirement goals. Our solutions are
                designed to maximize benefits & secure your financial future.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export { Hero };
