import { Section } from "../../components";

const Heading = () => {
  return (
    <Section bgClassName="" sectionClassName="pt-[120px] pb-4">
      <h1
        className="text-3xl text-center lg:text-5xl uppercase font-bold text-blackBg"
        data-aos="fade-down"
      >
        questionnaire
      </h1>{" "}
    </Section>
  );
};

export { Heading };
