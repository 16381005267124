import { OptionType } from "../components/select-input";

export const statesAndCapitals: OptionType[] = [
  { label: "Abia", value: "Abia" },
  { label: "Adamawa", value: "Adamawa" },
  { label: "Akwa Ibom", value: "Akwa Ibom" },
  { label: "Anambra", value: "Anambra" },
  { label: "Bauchi", value: "Bauchi" },
  { label: "Bayelsa", value: "Bayelsa" },
  { label: "Benue", value: "Benue" },
  { label: "Borno", value: "Borno" },
  { label: "Cross River", value: "Cross River" },
  { label: "Delta", value: "Delta" },
  { label: "Ebonyi", value: "Ebonyi" },
  { label: "Edo", value: "Edo" },
  { label: "Ekiti", value: "Ekiti" },
  { label: "Enugu", value: "Enugu" },
  { label: "Gombe", value: "Gombe" },
  { label: "Imo", value: "Imo" },
  { label: "Jigawa", value: "Jigawa" },
  { label: "Kaduna", value: "Kaduna" },
  { label: "Kano", value: "Kano" },
  { label: "Katsina", value: "Katsina" },
  { label: "Kebbi", value: "Kebbi" },
  { label: "Kogi", value: "Kogi" },
  { label: "Kwara", value: "Kwara" },
  { label: "Lagos", value: "Lagos" },
  { label: "Nasarawa", value: "Nasarawa" },
  { label: "Niger", value: "Niger" },
  { label: "Ogun", value: "Ogun" },
  { label: "Ondo", value: "Ondo" },
  { label: "Osun", value: "Osun" },
  { label: "Oyo", value: "Oyo" },
  { label: "Plateau", value: "Plateau" },
  { label: "Rivers", value: "Rivers" },
  { label: "Sokoto", value: "Sokoto" },
  { label: "Taraba", value: "Taraba" },
  { label: "Yobe", value: "Yobe" },
  { label: "Zamfara", value: "Zamfara" },
  { label: "FCT", value: "FCT" }, // Federal Capital Territory (Abuja)
];

export const religionOptions: OptionType[] = [
  { label: "Islam", value: "Islam" },
  { label: "Christianity", value: "Christianity" },
  { label: "Other Religions", value: "Others" },
];

export const maritalStatusOptions: OptionType[] = [
  { label: "Married", value: "Married" },
  { label: "Single", value: "Single" },
  { label: "Divorced", value: "Divorceds" },
  { label: "Widowed", value: "Widowed" },
  { label: "Separated", value: "Separated" },
];

export const incomeOptions: OptionType[] = [
  { label: "Less than 15million", value: "Less than 15million" },
  { label: "15million - 30million", value: "15million - 30million" },
  { label: "31million - 60million", value: "31million - 60million" },
  { label: "61million - 100million", value: "61million - 100million" },
  { label: "100million - above", value: "100million - above" },
];
